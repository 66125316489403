import React from 'react';

export const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">
      <p><span className="footer-title">Gone Country</span> &copy;2021</p>
      </div>
    </div>
  )
}
