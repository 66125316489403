
export const Navlinks = [
  {
    id: 1,
    text: 'Home',
    url: 'https://donegonecountry.com#main-home'
  },
  {
    id: 2,
    text: 'Articles',
    url: '/posts'
  },
  {
    id: 3,
    text: 'The Latest',
    url: 'https://donegonecountry.com#featured'
  },
  {
    id: 4,
    text: 'About',
    url: 'https://donegonecountry.com#about'
  }
]


  // ids:
  // main-container
  // featured
  // about
